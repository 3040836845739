<template>
    <div class="carve">
        <h5header :name="title" :right="false" />
        <div class="carve-con">
            <div class="modal-tips">
                <span v-if="carveId == 1"><label>{{ $t('reward.desc135') }}</label> {{ $t('reward.desc114', {
                    n1: configInfo.activityParamOne * 100, n2:
                        configInfo.activityParamThree * 100
                }) }}</span>
                <span v-else><label>{{ $t('reward.desc135') }}</label>{{ $t('reward.desc115', {
                    n1: configInfo.activityParamOne * 100, n2:
                        configInfo.activityParamTwo * 100
                }) }}</span>
            </div>
            <div class="tbody-info" v-for="item in carveList" :key="item.id">
                <div class="info flex">
                    <div>{{ $t('reward.desc117') }}</div>
                    <p><span v-if="carveId == 1">{{ $t('reward.desc126') }}</span><span v-else>{{
                        $t('reward.desc127') }}</span></p>

                </div>
                <div class="info flex">
                    <div>{{ $t('reward.desc118') }}</div>
                    <p>{{ item.peopleNumber }}</p>
                </div>
                <div class="info flex">
                    <div>{{ $t('reward.desc119') }}</div>
                    <p><span v-if="carveId == 1">{{ item.weekAmount }}</span><span v-else>{{ item.monthAmount
                    }}</span> DU</p>
                </div>
                <div class="info flex">
                    <div>{{ $t('reward.desc120') }}</div>
                    <p>{{ item.averageAmount }} DU</p>
                </div>
                <div class="info flex">
                    <div>{{ $t('reward.desc121') }}</div>
                    <p><span v-if="carveId == 1">{{ common.formatDate1(item.startWeekTime) }} - {{
                        common.formatDate1(item.endWeekTime) }}</span>
                        <span v-else>{{ common.formatDate1(item.startMonthTime) }} - {{
                            common.formatDate1(item.endMonthTime) }}</span>
                    </p>
                </div>
                <div class="info flex">
                    <div>{{ $t('reward.desc122') }}</div>
                    <p>{{ common.formatDate(item.grantTime) }}(GST)</p>
                </div>
                <div class="info flex">
                    <div>{{ $t('reward.desc123') }}</div>
                    <p><span v-if="item.useState == 1">{{ $t('reward.desc124') }}</span>
                        <span v-else>{{ $t('reward.desc125') }}</span>
                    </p>
                </div>
            </div>
            <div class="no-data" v-if="showNull">
                        <img src="../../public/images/no-data.png" alt="" />
                        <span>{{ $t("record.desc13") }}</span>
                    </div>
        </div>
    </div>
</template>

<script>
import h5header from "@/componets/h5header.vue";
export default {
    components: {
        h5header
    },
    data() {
        return {
            title: '',
            carveList: [],
            showNull: false,
            configInfo: {},
            carveId: 1
        }
    },
    mounted() {
        this.$route.query.act == 1 ? this.title = this.$t('reward.desc112') : this.title = this.$t('reward.desc113');
        this.carveId = this.$route.query.act;
        document.title = this.title;
        this.$post(this.URL.reward.land, {
            activityType: 2
        }).then(res => {
            if (res.code == 0) {
                if (res.data.awardAccountType == 1) {
                    res.data.awardAccountTypeName = this.$t('new.desc7')
                } else if (res.data.awardAccountType == 2) {
                    res.data.awardAccountTypeName = this.$t('new.desc8')
                } else {
                    res.data.awardAccountTypeName = this.$t('new.desc9')
                }
                this.configInfo = res.data;
            }
        })
        this.getlist();
    },
    methods: {
        getlist() {
            this.$post(this.URL.reward.carve, {
                type: this.$route.query.act
            }).then(res => {
                if (res.code == 0) {
                 
                    if(res.data==''){
                        this.showNull = true;
                        return;
                    }
                    res.data.forEach( e =>{
                        if(e.useState==1){
                            this.carveList.push(e)
                        }
                    })
                    if (this.carveList.length == 0) {
                        this.showNull = true;
                    }
                    if(res.data.length>0){
                      this.carveList.forEach( e =>{
                            let time1 = 4 * 60 * 60 * 1000;
                            e.grantTime=e.grantTime-time1;
                            e.startWeekTime=e.startWeekTime -time1;
                            e.endWeekTime=e.endWeekTime -time1;
                            e.startMonthTime=e.startMonthTime -time1;
                            e.endMonthTime=e.endMonthTime -time1;
                        })
                    }
                } else {
                    this.$message(res.message)
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.carve {
    padding: 56px 16px;

    .modal-tips {
        margin-bottom: 10px;
        border-radius: 8px;
        background: #181818;
        padding: 16px 4px 16px 16px;
        font-size: 13px;
        color: #fff;

        label {
            color: #E15757;
        }
    }

    .tbody-info {
        margin-bottom: 10px;
        padding: 16px;
        border-radius: 8px;
        background: #181818;
        .info{
            margin-bottom: 16px;
            font-size: 13px;
            color: rgba(255, 255, 255, 0.6);
            &:last-child{
                margin-bottom: 0;
            }
            div{
                flex: 0 0 40%;
            }
            p{
                flex: 0 0 60%;
                text-align: right;
                color: #fff;
            }
        }
    }
}
.no-data {
    width: 100%;
    padding: 40px 0;

    img {
        display: block;
        width: 120px;
        height: 120px;
        margin: 0 auto 16px;
    }

    span {
        display: block;
        margin: 0 auto;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #a1a1a1;
    }
}

</style>